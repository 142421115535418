const config = {
  base_url: process.env.REACT_APP_DEVELOPMENT_BASEURL,
  Kill_trade_base_url: process.env.REACT_APP_DEVELOPMENT_ExitTrade_BASEURL,
  Socket_base_url: process.env.REACT_APP_DEVELOPMENT_SOCKETURL,
  // base_url: process.env.REACT_APP_PRODUCTION_BASEURL,
  // Kill_trade_base_url: process.env.REACT_APP_PRODUCTION_ExitTrade_BASEURL,
  // Socket_base_url: process.env.REACT_APP_PRODUCTION_SOCKETURL,
  //prod api starts
  //delete api
  virtual_trade_delete: '/virtual-trade',
  live_trade_delete: '/live-trade',
  BT_trade_delete: '/admin/delete-requests',
  virtual_trade_delete_multiple: '/virtual-trade/multiple-delete',
  live_trade_delete_multiple: '/live-trade/multiple-delete',
  BT_trade_delete_multiple: '/admin/multiple-delete',

  //signUP
  sign_up: '/admin/add-user',
  verify_email: '/admin/resend-email-verify',

  //login
  login: '/user/signin',
  Check_email_password: '/user/user-login-email-check',

  //forgot password
  forgot_pass: '/admin/forgot-password-mail',
  resend_email_pass: '/admin/resend-email-forgotpass',
  set_password_link: '/user/user-set-password-mail',

  //reset password
  reset_pass: '/admin/reset-password',

  // set password

  set_pass: '/user/set-user-pass',

  //user_list
  user_list: '/admin/user-list',
  add_user: '/admin/add-user',
  user_by_id: '/admin/user-by-id',
  update_user: '/admin/update-user',
  change_user_email: '/admin/change-user-email',
  change_user_pass: '/admin/change-user-pass',
  change_email_otp: '/user/verification/email/change-otp',

  //virtual trade
  virtual_trade: '/virtual-trade',
  list_virtual_trade: '/virtual-trade',

  //backtesting
  add_request: '/admin/add-requests',
  update_backtesting_request: '/admin/update-requests',

  //live trade
  live_trade: '/live-trade',
  list_live_trade: '/live-trade',

  //all stocks
  all_stocks: '/user/all-stock-list',

  //get request list
  get_request_list: '/admin/get-requests-list',
  get_request: '/admin/get-requests',

  get_admin_List: '/admin/admin-all-strategy-list',
  add_subscription: '/admin/add-st-permission',
  remove_subscription: '/admin/remove-st-permission',
  get_subsciption: '/admin/user-subscribe-strategy-list',

  //change user password:
  // change_user_pass: '/admin/change-user-pass',

  //HEAT MAP p and l list
  heatmap: '/user/p-and-l-list',

  //candles
  b_candels: '/brokers/candles',

  //broker
  broker: '/brokers',
  broker_crendientials: '/brokers/credential',

  //strategy
  get_strategy: '/admin/strategy',
  strategy_permission: '/admin/add-st-permission',

  //Kill trade
  exit_LT: '/kill_live',
  exit_VT: '/kill_virtual',

  //Play Pause
  play: '/request_status',

  checkEmail: '/admin/email-check',
  checkVerifiedUser: '/admin/email-verify',

  twoFactorAuth: '/user/verification/authentication/otp',
  resendFactorAuth: '/user/verification/authentication/resend-otp',

  //logout
  logOut: '/user/logged/out',

  //active-log
  active_log: '/user/activity-log',
  update_Profile_Img: '/user/update-img',

  //payment-cards
  payment_cards: '/user/card-list',
  add_cards: '/user/create-card',
  delete_cards: '/user/card-delete',
  update_card: '/user/update-card',
  payment_list: '/payment/payment-list',
  //addresses
  address_list: '/user/address-list',
  create_address: '/user/create-address',
  update_address: '/user/update-address',
  delete_address: '/user/address-delete',

  //plan
  user_active_plan: '/plan/active-plan-details',
  get_allPlans: '/plan',

  //Exit trade
  // exit_trade: 'https://orders.api.winraylabs.com',

  //ticket support
  get_all_tickets: '/ticket',
  create_ticket: '/ticket',
  ticket_replies: '/ticket/replies-list',
  ticket_message: '/ticket/replies',
  ticket_delete: '/ticket/delete',

  //logs
  strategy_logs: '/user/strategy-activity-log',

  //reports
  user_trade_list: "/user/user-trades-list"
};

export default config;
